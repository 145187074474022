import React from "react";
import { Consumer, FlashActionProviderInterface } from "./Context";

type WithFlashMessagesProps<P> = Omit<P, keyof FlashActionProviderInterface>;

export default function withFlashMessages(
    WrappedComponent: React.ComponentType<any>
): React.FC<WithFlashMessagesProps<any>> {
    return (props: any) => (
        <Consumer>{flashMessages => <WrappedComponent {...props} flashMessages={flashMessages} />}</Consumer>
    );
}
