import React from "react";
import { IntlProvider, injectIntl, IntlShape as BaseShape } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import LanguageService from "dataProvider/Language";
import LoaderUI from "ui/Loader";

export type IntlShape = Omit<Omit<BaseShape, "formatMessage">, "formatDate"> & {
    formatMessage: (id: string, values?: Record<string, PrimitiveType>) => string;
    formatDate: (value: string | number | Date | undefined) => string;
};

const IntlContext = React.createContext<IntlShape>({} as IntlShape);

class BaseProvider extends React.Component<{ intl: BaseShape }> {
    formatMessage = (id: string, values?: Record<string, PrimitiveType>): string => {
        return this.props.intl.formatMessage({ id }, values);
    };
    formatDate = (value: string | number | Date | undefined) => {
        if (typeof value === 'string') {
            value = value.replace('/', '-').substr(0, 10);
        }
        return this.props.intl.formatDate(value);
    }

    render() {
        return (
            <IntlContext.Provider value={{ ...this.props.intl, formatMessage: this.formatMessage, formatDate: this.formatDate }}>
                {this.props.children}
            </IntlContext.Provider>
        );
    }
}

const SubProvider = injectIntl(BaseProvider);

class Provider extends React.Component {
    state = {
        translations: {},
        localeCode: "fr",
        status: "IDLE"
    };

    componentDidMount = () => {
        this.loadTranslations(this.state.localeCode);
    };

    loadTranslations = (localeCode: string) => {
        this.setState({
            status: "LOADING"
        });
        return LanguageService.list(localeCode).then(res => {
            this.setState({
                translations: res.data.data,
                status: "IDLE",
                localeCode
            });
        });
    };

    render() {
        if (this.state.status === "LOADING") {
            const loadingMessages: HashMap<string> = {
                fr: "Chargement..."
            };
            return (
                <div className="loader-backdrop-wrapper loader-backdrop-wrapper--full">
                    <div className="loader-backdrop" />
                    <LoaderUI message={loadingMessages[this.state.localeCode]} />
                </div>
            );
        }
        return (
            <IntlProvider locale={this.state.localeCode} key={this.state.localeCode} messages={this.state.translations}>
                <SubProvider>{this.props.children}</SubProvider>
            </IntlProvider>
        );
    }
}

const Consumer = IntlContext.Consumer;

export { Consumer, Provider, IntlContext as Context };
