import React, { useContext, useState } from "react";
import { Context as AuthContext } from "context/Auth";
import Logo from "assets/images/header-logo.png";
import { NavigationElement } from "helpers/navigation";
import { useHistory } from "react-router-dom";
import NavigationItem from "./NavigationItem";


const Topbar: React.FC = () => {
    const [ isMenuOpen, openMenu ] = useState<boolean>(false);

    const authData = useContext(AuthContext);
    const navigation = authData.getNavigation();
    const history = useHistory();

    return (
        <header className="header">
            <img
                src={ Logo }
                alt="Logo"
                className="header_logo"
                onClick={ () => history.push('/') }
            />

            <nav className={ "header_menu " + (isMenuOpen ? "open" : '') }>
                <ul>
                    { navigation.map((navigationElement: NavigationElement) => {
                        return (
                            <NavigationItem
                                key={ `navigation-item-` + navigationElement.route }
                                element={ navigationElement }
                                onNavigate={ () => openMenu(false) }
                            />
                        );
                    }) }
                </ul>
            </nav>

            <i className="header_burger lnr lnr-menu" onClick={ () => openMenu(!isMenuOpen) }></i>
        </header>
    );
};

export default Topbar;
