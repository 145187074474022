import React, { Suspense } from "react";
import { Provider as RefsProvider } from "context/Refs";
import { Topbar } from "./components";
import Loader from "components/App/Loader";
import Bottombar from "./components/Bottombar";

const Main: React.FC = (props) => {
    return (
        <RefsProvider>
            <React.Fragment>
                <Topbar />

                <div className="main">
                    <Suspense fallback={ <Loader /> }>
                        { props.children }
                    </Suspense>
                </div>

                <Bottombar />
            </React.Fragment>
        </RefsProvider>

    );
};

export default Main;
