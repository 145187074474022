import fetch from "./fetch";
import { AxiosResponse } from "axios";

class Refs {
    all(params?: HashMap<any>): Promise<AxiosResponse<{ data: RefsInterface }>> {
        return fetch.get(`refs/`, { params });
    }
}

export default new Refs();
