import React from "react";
import fetch from "dataProvider/fetch";
import AuthenticationService from "dataProvider/AuthenticationService";
import { navigation, NavigationElement } from "helpers/navigation";

interface Account {
    user: {
        id: string;
        gender: string;
        first_name: string;
        last_name: string;
        username: string;
        email: string;
        role: {
            id: string;
            name: string;
        };
    };
    capabilities: string[];
    resources: any;
}

export interface AuthenticationProviderInterface extends Readonly<{}> {
    isLoggedIn: Boolean;
    context: boolean;
    account: Account | null;
    login: (authInfo: { username: string; password: string }) => Promise<void>;
    requestCode: (code: string) => Promise<void>;
    loginUsingCode: (code: string, login_code: string) => Promise<void>;
    setActivity: (activity: string) => Promise<void>;
    logout: () => Promise<void>;
    getNavigation: () => NavigationElement[];
    isAllowed: (acl: string) => boolean;
    isAuthor: (authorId: string) => boolean;
    setContext: (context: boolean) => void;
    hasContext: () => boolean;
}

const ACCOUNT_KEY: string = "account";
const CONTEXT_KEY: string = "context";

function getAccount(): Account | null {
    if (fetch.sessionIsExpired()) {
        return null;
    }
    try {
        const account = localStorage.getItem(ACCOUNT_KEY);
        if (account) {
            return JSON.parse(account);
        }
        return null;
    } catch (err) {
        return null;
    }
}

function isLoggedIn(): boolean {
    return !!getAccount();
}

const AuthContext = React.createContext<AuthenticationProviderInterface>({} as AuthenticationProviderInterface);

class AuthProvider extends React.Component<Readonly<{}>, AuthenticationProviderInterface> {
    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            isLoggedIn: false,
            context: false,
            account: null,
            login: this.login,
            requestCode: this.requestCode,
            loginUsingCode: this.loginUsingCode,
            setActivity: this.setActivity,
            logout: this.logout,
            getNavigation: this.getNavigation,
            isAllowed: this.isAllowed,
            isAuthor: this.isAuthor,
            setContext: this.setContext,
            hasContext: this.hasContext,
        };
    }

    componentDidMount = async () => {
        if (isLoggedIn()) {
            try {
                const res = await AuthenticationService.fresh();
                const account = Object.assign({}, res.data.data) as Account;
                localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account));
                this.setState({ isLoggedIn: true, account: account });
            } catch (e) {
                this.logout();
            }
        }
    };

    login = async ({ username, password }: { username: string; password: string }) => {
        const res = await AuthenticationService.login(username, password);
        const account = Object.assign({}, res.data.data) as Account;
        localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account));
        this.setState({ isLoggedIn: true, account: account });
    };

    requestCode = async (code: string) => {
        await AuthenticationService.requestCode(code);
    };

    loginUsingCode = async (code: string, login_code: string) => {
        const res = await AuthenticationService.loginUsingCode(code, login_code);
        const account = Object.assign({}, res.data.data) as Account;
        localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account));
        this.setState({ isLoggedIn: true, account: account });
    };

    setActivity = async (activity: string) => {
        const res = await AuthenticationService.setActivity(activity);
        const account = Object.assign({}, res.data.data) as Account;
        localStorage.setItem(ACCOUNT_KEY, JSON.stringify(account));
        this.setState({ account: account});
    };

    logout = async () => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const bugIE = await AuthenticationService.logout();
        } finally {
            localStorage.removeItem(ACCOUNT_KEY);
            localStorage.removeItem(CONTEXT_KEY);
            this.setState({ isLoggedIn: false, account: null });
        }
    };

    getNavigation = () => {
        const _recursiveFilter = (array: NavigationElement[]) => {
            let results: NavigationElement[] = [];

            for (const navItem of array) {
                if (navItem.acl && !this.isAllowed(navItem.acl)) {
                    continue;
                }
                if (navItem.children) {
                    navItem.children = _recursiveFilter(navItem.children);
                }
                results.push(navItem);
            }

            return results;
        }

        const secureNav = _recursiveFilter(navigation(this.state));
        return secureNav;
    }


    isAllowed = (acl: string) => {
        if (!this.state.account) {
            return false;
        }

        const capabilities = this.state.account.capabilities;
        for (const cap of capabilities) {
            // Matches exact cap
            if (acl === cap) {
                return true;
            }

            // Matches super cap
            const index = acl.indexOf(cap);
            if (index === 0 && acl.substr(cap.length, 1) === '.') {
                return true;
            }

            // Matches joker caps
            if (acl.indexOf('*') !== -1) {
                const subAcl = acl.substr(0, acl.indexOf('*') - 1);
                const index = cap.indexOf(subAcl);
                if (index === 0 && cap.substr(subAcl.length, 1) === '.') {
                    return true;
                }
            }
        }

        return false;
    }

    isAuthor = (authorID: string) => {
        if (!this.state.account) {
            return false;
        }

        return this.state.account.user.id === authorID;
    };

    setContext = (context: boolean) => {
        localStorage.setItem(CONTEXT_KEY, JSON.stringify(context));
    }

    hasContext = () => {
        let context = localStorage.getItem(CONTEXT_KEY)
        return context ? JSON.parse(context) : false;
    }

    render() {
        return <AuthContext.Provider value={ this.state }>{ this.props.children }</AuthContext.Provider>;
    }
}

const Consumer = AuthContext.Consumer;

export { AuthProvider as Provider, Consumer, AuthContext as Context };
