import React, { useContext } from "react";
import { Context as IntlContext } from "context/Intl";
import { useLocation, useHistory } from "react-router-dom";
import { NavigationElement } from "helpers/navigation";

const NavigationItem: React.FC<{ element: NavigationElement, onNavigate?: () => void, level?: number }> = ({ element, onNavigate, level = 1 }) => {
    const intl = useContext(IntlContext);
    const location = useLocation();
    const history = useHistory();

    let classes = [];
    if (element.route) {
        const routeToCheck = element.route.replace(/\/$/, '') + '/';

        // If a no-slash-ending route includes a slash-ending route at its start, it's a parent
        if (location.pathname.indexOf(routeToCheck) === 0) {
            classes.push('parent');
        }

        if (routeToCheck === location.pathname.replace(/\/$/, '') + '/') {
            classes.push('active');
        }
    }
    if (element.className) {
        classes.push(element.className);
    }

    const navigate = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (element.route) {
            history.push(element.route);
            onNavigate && onNavigate();
        } else if (element.onClick) {
            element.onClick();
        }
    }

    return (
        <li
            className={ classes.join(' ') }
            onClick={ navigate }
        >
            { element.skipIntl ? element.label : intl.formatMessage(element.label) }

            { element.children && element.children.length > 0 &&
                <ul className={ `sublevel-${ level }` }>
                    { element.children.map((child: NavigationElement) => {
                        return (
                            <NavigationItem
                                key={ `navigation-route-` + child.route }
                                element={ child }
                                level={ level + 1 }
                            />
                        );
                    }) }
                </ul>
            }
        </li>
    );
}


export default NavigationItem;
