import React from "react";
import { Route, RouteProps, Switch } from "react-router-dom";

interface PaginationRouteProps extends RouteProps {
    tags?: string[];
}

const PaginationRoute: React.FC<PaginationRouteProps> = (props: PaginationRouteProps) => {
    const { path, component } = props;
    let { tags } = props;
    const Component: any = component;

    if (!tags) {
        tags = ['page'];
    }

    return (
        <Switch>
            { tags.map((tag: string) => {
                return (
                    <Route key={"route__" + tag } path={ path + '/' + tag + '/:' + tag } render={ () => {
                        return <Component />;
                    } } />
                );
            }) }

            <Route path={ path } component={ component } { ...props } exact={ true } />
        </Switch>
    );
};

export default PaginationRoute;
