import { AuthenticationProviderInterface } from "context/Auth/Context";
import { REFS_KEY } from "context/Refs/Context";

export interface NavigationElement {
    label: string;
    skipIntl?: boolean;
    route?: string;
    onClick?: () => void;
    acl?: string;
    className?: string;
    children?: NavigationElement[];
};

export const navigation = (authData: AuthenticationProviderInterface): NavigationElement[] => {
    let loginChildren: NavigationElement[] = [
        {
            label: "menu.logout",
            onClick: () => {
                authData.logout();
                localStorage.removeItem(REFS_KEY);
            },
        },
    ];

    if (authData.hasContext()) {
        loginChildren.unshift({
            label: "menu.changeActivity",
            onClick: () => {
                authData.setActivity('');
            },
        });
    }

    return [
        {
            label: "menu.dashboard",
            route: "/dashboard",
            acl: "report",
        },
        {
            label: "menu.report",
            route: "/reports",
            acl: "report",
        },
        {
            label: "menu.alert",
            route: "/alerts",
            acl: "alert",
        },

        {
            label: "menu.admin",
            route: "/admin",
            acl: "admin.*",
            children: [
                {
                    label: "menu.admin.users",
                    route: "/admin/user",
                    acl: "admin.users",
                },
                {
                    label: "menu.admin.dealers",
                    route: "/admin/dealer",
                    acl: "admin.dealer",
                },
                {
                    label: "menu.admin.types",
                    route: "/admin/type",
                    acl: "admin.type",
                },
                {
                    label: "menu.admin.topics",
                    route: "/admin/topic",
                    acl: "admin.topic",
                },
                {
                    label: "menu.admin.alert.themes",
                    route: "/admin/alert-theme",
                    acl: "admin.alert.theme",
                },
                {
                    label: "menu.admin.todos",
                    route: "/admin/todo",
                    acl: "admin.todo",
                },
                {
                    label: "menu.admin.notes",
                    route: "/admin/note",
                    acl: "admin.note",
                },
            ]
        },


        {
            label: `${authData.account?.user.first_name} ${authData.account?.user.last_name}`,
            skipIntl: true,
            className: 'right',
            children: loginChildren,
        },
    ]
}
