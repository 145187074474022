import React from "react";
import "assets/scss/minimal.scss";
import Logo from "assets/images/logo-login.png";

const Minimal: React.FC = ({ children }) => {
    return (
        <div className="minimal">
            <div className="minimal-container">
                <img src={ Logo } alt="Logo" className="minimal-logo" />
                { children }
            </div>
        </div>
    );
};

export default Minimal;
