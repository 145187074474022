import React from "react";
import Message from "ui/FlashMessages/Message";
import { FlashMessageStateInterface, FlashMessage } from "context/FlashMessages/Context";

type Props = {
    flash: FlashMessageStateInterface;
};

const animationDuration = 750;

class FlashMessages extends React.Component<Props> {
    onFlashFadeOut(flashMessage: FlashMessage) {
        const { flash } = this.props;
        flash.actions.removeFlashMessage(flashMessage.id);
    }

    render() {
        const { flash } = this.props;
        return (
            <div className="flash-message-container">
                {flash.messages.map(message => (
                    <Message
                        key={message.id}
                        onFadeOut={() => this.onFlashFadeOut(message)}
                        duration={message.duration}
                        animationDuration={animationDuration}
                        content={message.text}
                        type={message.type}
                    />
                ))}
            </div>
        );
    }
}

export default FlashMessages;
