import axios, { AxiosResponse } from "axios";

const SESSION_TOKEN_KEY: string = String(process.env.REACT_APP_SESSION_HEADER_NAME);
const SESSION_EXPIRATION_DATE_KEY: string = `${SESSION_TOKEN_KEY}_expiration_date`;

function setSession(sessionId: string, expirationDate: string) {
    localStorage.setItem(SESSION_TOKEN_KEY, sessionId);
    localStorage.setItem(SESSION_EXPIRATION_DATE_KEY, expirationDate);
}

function clearSession() {
    localStorage.removeItem(SESSION_TOKEN_KEY);
    localStorage.removeItem(SESSION_EXPIRATION_DATE_KEY);
}

function getSessionId(): string | null {
    return localStorage.getItem(SESSION_TOKEN_KEY);
}

function getSessionExpirationDate(): Date | null {
    const data = localStorage.getItem(SESSION_EXPIRATION_DATE_KEY);
    if (data) {
        return new Date(data);
    }
    return null;
}

function mergeConfig(config: HashMap<any>): HashMap<any> {
    const sessionId = getSessionId();
    if (sessionId) {
        let headers: HashMap<any> = {};
        headers[SESSION_TOKEN_KEY] = sessionId;
        return Object.assign({}, config, { headers });
    }
    return config;
}

function sessionIsExpired(): boolean {
    const expirationDate = getSessionExpirationDate();
    if (expirationDate) {
        return expirationDate < new Date();
    }
    return true;
}

const API_URL = process.env.REACT_APP_SERVER_URL;

class fetch {
    sessionIsExpired() {
        return sessionIsExpired();
    }

    get(url: string, config: HashMap<any> = {}): CancellablePromise<AxiosResponse<any>> {
        const CancelToken = axios.CancelToken.source();
        let query: Partial<CancellablePromise<AxiosResponse<any>>> = axios.get(
            API_URL + url,
            mergeConfig(Object.assign({}, config, { cancelToken: CancelToken.token }))
        );
        query.cancel = CancelToken.cancel;
        return query as CancellablePromise<AxiosResponse<any>>;
    }

    delete(url: string, config: HashMap<any> = {}): CancellablePromise<AxiosResponse<any>> {
        const CancelToken = axios.CancelToken.source();
        let query: Partial<CancellablePromise<AxiosResponse<any>>> = axios.delete(
            API_URL + url,
            mergeConfig(Object.assign({}, config, { cancelToken: CancelToken.token }))
        );
        query.cancel = CancelToken.cancel;
        return query as CancellablePromise<AxiosResponse<any>>;
    }

    post(url: string, data?: any, config: HashMap<any> = {}): CancellablePromise<AxiosResponse<any>> {
        const CancelToken = axios.CancelToken.source();
        let query: Partial<CancellablePromise<AxiosResponse<any>>> = axios.post(
            API_URL + url,
            data,
            mergeConfig(Object.assign({}, config, { cancelToken: CancelToken.token }))
        );
        query.cancel = CancelToken.cancel;
        return query as CancellablePromise<AxiosResponse<any>>;
    }

    put(url: string, data: any, config: HashMap<any> = {}): CancellablePromise<AxiosResponse<any>> {
        const CancelToken = axios.CancelToken.source();
        let query: Partial<CancellablePromise<AxiosResponse<any>>> = axios.put(
            API_URL + url,
            data,
            mergeConfig(Object.assign({}, config, { cancelToken: CancelToken.token }))
        );
        query.cancel = CancelToken.cancel;
        return query as CancellablePromise<AxiosResponse<any>>;
    }

    patch(url: string, data: any, config: HashMap<any> = {}): CancellablePromise<AxiosResponse<any>> {
        const CancelToken = axios.CancelToken.source();
        let query: Partial<CancellablePromise<AxiosResponse<any>>> = axios.patch(
            API_URL + url,
            data,
            mergeConfig(Object.assign({}, config, { cancelToken: CancelToken.token }))
        );
        query.cancel = CancelToken.cancel;
        return query as CancellablePromise<AxiosResponse<any>>;
    }
}

const query_debug = function(response: AxiosResponse<any>) {
    if (process.env.NODE_ENV === "development") {
        if (response.data && response.data.debug) {
            const debug = response.data.debug;
            const config = response.config;
            if (debug.warnings && debug.warnings.length) {
                console.groupCollapsed(
                    `%c Request warning : ${config.method} ${config.url}`,
                    "color: #856404; background-color: #fff3cd; border-color: #ffeeba;"
                );
                console.log(debug.warnings);
                console.groupEnd();
            }
            if (debug.custom_data) {
                console.groupCollapsed(
                    `%c Request custom data : ${config.method} ${config.url}`,
                    "color: #0c5460; background-color: #d1ecf1; border-color: #bee5eb;"
                );
                for (let index = 0; index < debug.custom_data.length; index++) {
                    const element = debug.custom_data[index];
                    console.log(element);
                }
                console.groupEnd();
            }
        }
    }
};

const isCachedRequest = (response: AxiosResponse<any>): boolean => {
    if (response.status === 304) {
        return true;
    }
    if (response.headers["cache-control"]) {
        return !!response.headers["cache-control"].split(",").find((value: string) => {
            return !!value.trim().match(/^max-age=[1-9]/);
        });
    }
    return false;
};

// Axios Session interceptor
axios.interceptors.response.use(
    function(response: AxiosResponse<any>) {
        // Session data
        if (response.headers[SESSION_TOKEN_KEY] && !isCachedRequest(response)) {
            setSession(response.headers[SESSION_TOKEN_KEY], response.headers[SESSION_EXPIRATION_DATE_KEY]);
        }
        query_debug(response);
        return response;
    },
    function(error: any) {
        if (error.response) {
            if (error.response.headers[SESSION_TOKEN_KEY]) {
                setSession(
                    error.response.headers[SESSION_TOKEN_KEY],
                    error.response.headers[SESSION_EXPIRATION_DATE_KEY]
                );
            }
            query_debug(error.response);

            if (error.response.status === 401) {
                clearSession();
            }
        }
        // Do something with response error
        return Promise.reject(error);
    }
);

const singleton = new fetch();
export default singleton;
