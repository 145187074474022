import fetch from "./fetch";

class Authentication {
    login(username: string, password: string) {
        return fetch.post(`authentication/login/`, {
            username,
            password,
        });
    }

    logout() {
        return fetch.post(`authentication/logout/`);
    }

    fresh() {
        return fetch.get(`authentication/fresh/`);
    }

    requestCode(code: string) {
        return fetch.post(`authentication/requestCode/`, {
            code
        });
    }

    loginUsingCode(code: string, login_code: string) {
        return fetch.post(`authentication/loginCode/`, {
            code,
            login_code,
        });
    }

    setActivity(activity: string) {
        return fetch.post(`authentication/setActivity/`, {
            activity,
        });
    }
}

export default new Authentication();
