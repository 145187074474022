import React from "react";
import { useHistory } from "react-router-dom";

const DispatcherRouter: React.FC = () => {
    const history = useHistory();
    history.push('/dashboard');
    return null;
};

export default DispatcherRouter;
