import React from "react";
import LoaderUI from "ui/Loader";

const Loader: React.FC = () => {
    return (
        <div className="loader fullsize">
            <LoaderUI message={ "Chargement..." } />
        </div>
    );
};

export default Loader;
