import React from "react";
import RefsDataProvider from "dataProvider/Refs";

const REFS_KEY: string = "refs";

const RefsContext = React.createContext<RefsInterface>({} as RefsInterface);

class RefsProvider extends React.Component<Readonly<{}>, RefsInterface> {
    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            dealers: [],
            activities: [],
            notes: [],
            trades: [],
            topics: [],
            types: [],
            alertThemes: [],
            todos: [],
        };
    }

    componentDidMount = async () => {
        try {
            const payload = await RefsDataProvider.all();
            const refs = Object.assign({}, payload.data.data) as RefsInterface;
            localStorage.setItem(REFS_KEY, JSON.stringify(refs));
            this.setState({
                dealers: refs.dealers,
                activities: refs.activities,
                notes: refs.notes,
                trades: refs.trades,
                topics: refs.topics,
                types: refs.types,
                alertThemes: refs.alertThemes,
                todos: refs.todos,
            });
        } catch (e) {

        }
    };

    render() {
        return <RefsContext.Provider value={ this.state }>{ this.props.children }</RefsContext.Provider>;
    }
}

const Consumer = RefsContext.Consumer;

export { RefsProvider as Provider, Consumer, RefsContext as Context, REFS_KEY };
