import React from "react";
import { FormattedHTMLMessage } from "react-intl";

const Bottombar: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer_wrapper">
                <FormattedHTMLMessage id={ "generic.copyright" } />
            </div>
        </footer>
    );
};

export default Bottombar;
