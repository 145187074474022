import React from "react";

type Props = {
    message?: string;
};

const Loader: React.FC<Props> = ({ message }) => {
    return (
        <div className="loader_wrapper">
            <div className="loader_item" />
            { message &&
                <div className="text-center">
                    <p className="loader_text">{ message }</p>
                </div>
            }
        </div>
    );
};

export default Loader;
