import React from "react";
import ReactDOM from "react-dom";

interface Props {
    duration: number | null;
    animationDuration: number;
    type: string;
    content: string;
    onFadeOut?: () => void;
}

class FlashMessage extends React.Component<Props> {
    state = {
        show: true,
    };

    timeOutId: NodeJS.Timeout | null = null;

    fadeOut() {
        const { animationDuration, onFadeOut } = this.props;
        this.setState({ show: false });
        this.timeOutId && clearTimeout(this.timeOutId);
        if (onFadeOut) {
            this.timeOutId = setTimeout(() => onFadeOut(), animationDuration);
        }
    }

    componentDidMount() {
        if (this.props.duration && this.fadeOut) {
            this.timeOutId = setTimeout(() => this.fadeOut(), this.props.duration);
        }
    }

    componentWillUnmount() {
        this.timeOutId && clearTimeout(this.timeOutId);
    }

    render() {
        const { content, type } = this.props;
        const showClassName = this.state.show ? `flash-message--show` : `flash-message--hide`;
        const render = (
            <div onClick={ () => this.fadeOut() } className={ `flash-message flash-message--${type} ${showClassName}` }>
                <div className="flash-message__icon"></div>
                <div className="flash-message__content">{ content }</div>
            </div>
        );

        return ReactDOM.createPortal(render, document.getElementById("flash-message--root")!);
    }
}

export default FlashMessage;
