import React, { Suspense } from "react";
import "assets/scss/pdf.scss";
import Loader from "components/App/Loader";

const Pdf: React.FC = ({ children }) => {
    return (
        <React.Fragment>
            <div className="main main-pdf">
                <Suspense fallback={ <Loader /> }>
                    { children }
                </Suspense>
            </div>
        </React.Fragment>
    );
};

export default Pdf;
