import React, { useContext } from "react";
import { Provider as RefsProvider } from "context/Refs";
import { Context as AuthContext } from "context/Auth";
import { Switch, Route } from "react-router";
import { Main as MainLayout, Minimal as MinimalLayout} from "components/Layout/";
import Dispatch from "app/Dispatch";
import PaginationRoute from "components/Route/PaginationRoute";

const Authentication = React.lazy(() => import("app/Authentication"));
const DoubleAuthentication = React.lazy(() => import("app/Authentication/DoubleAuthentication"));
const SelectActivity = React.lazy(() => import("app/Authentication/SelectActivity"));
const Admin = React.lazy(() => import("app/Admin"));
const Dashboard = React.lazy(() => import("app/Dashboard"));
const Report = React.lazy(() => import("app/Report"));
const Alert = React.lazy(() => import("app/Alert"));
const PdfModule = React.lazy(() => import("app/Report/Pdf"));

const AuthModule: React.FC = () => {
    return (
        <MainLayout>
            <Switch>
                <Route path="/select/activity" render={ () => <SelectActivity /> } />
                <Route path="/admin" render={ () => <Admin /> } />
                <PaginationRoute path="/dashboard" component={ Dashboard } tags={ ['page', 'pageAct'] } />
                <Route path="/reports" render={() => <Report /> } />
                <Route path="/alerts" render={() => <Alert /> } />
                <Route path="/" render={ () => <Dispatch /> } />
            </Switch>
        </MainLayout>
    );
};

const PreAuthModule: React.FC = () => {
    return (
        <RefsProvider>
            <MinimalLayout>
                <Switch>
                    <Route path="/" render={ () => <SelectActivity /> } />
                </Switch>
            </MinimalLayout>
        </RefsProvider>
    );
};

const NoAuthModule: React.FC = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={ '/reports/pdf/:id/:mieczyslaw' } component={ PdfModule } />

                <Route path="/login/:code" render={ () => (
                    <MinimalLayout>
                        <DoubleAuthentication />
                    </MinimalLayout>
                )} />
                <Route render={ () => (
                    <MinimalLayout>
                        <Authentication />
                    </MinimalLayout>
                )}  />
            </Switch>
        </React.Fragment>

    );
};

const ModuleRouter: React.FC = () => {
    const auth = useContext(AuthContext);

    if (auth.isLoggedIn) {
        if (auth.account?.resources.activity === '*') {
            auth.setContext(true);
            return <PreAuthModule />;
        }
        return <AuthModule />;
    }
    return <NoAuthModule />;
};

export default ModuleRouter;
